<template>
  <el-card id="processed">
    <header>
      <h1>已处理续费查询</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司ID</span>
            <el-input v-model="id" placeholder="请输入公司ID" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>公司名称</span>
            <el-input v-model="realname" placeholder="请输入公司名称" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>客服人员</span>
            <el-input v-model="operators" placeholder="请输入客服人员" size="mini"></el-input>
          </div>

          <div class="left-wrap">
            <span>请选择</span>
            <el-select size="mini" v-model="renewResult" placeholder="续签结果">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap" style="width: 100%">
            <span style="width: 50px">拨打时间</span>
            <el-date-picker v-model="timeValue" type="datetimerange" @change="getDate" start-placeholder="开始日期"
              end-placeholder="结束日期" size="mini">
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini"
            class="searchBtn">查找</el-button>

          <el-button type="primary" icon="el-icon-refresh" @click="reset" size="mini" class="reset">重置</el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="公司ID" align="center"></el-table-column>
        <el-table-column prop="realname" label="公司名称" align="center"></el-table-column><el-table-column prop="username"
          label="登录账户" align="center"></el-table-column>
        <el-table-column prop="operators" label="客服" align="center"></el-table-column>
        <el-table-column prop="renew_result" label="续签结果" align="center" style="color: red">
          <template #default="scope">
            <span v-if="scope.row.renew_result == 0">关停</span>
            <span v-if="scope.row.renew_result == 1">已续费</span>
            <span v-if="scope.row.renew_result == 2">待续费</span>
            <span v-if="scope.row.renew_result == 3">商定中</span>
            <span v-if="scope.row.renew_result == 4">不续费</span>
            <span v-if="scope.row.renew_result == 5">转销售</span>
            <span v-if="scope.row.renew_result == 6">新增坐席</span>
            <span v-if="scope.row.renew_result == 7">减坐席</span>
            <span v-if="scope.row.renew_result == 8">补开坐席</span>
            <span v-if="scope.row.renew_result == 9">续费新增坐席</span>
            <span v-if="scope.row.renew_result == 10">续费减坐席</span>
          </template>
        </el-table-column>
        <el-table-column prop="seat_rate" label="月租" align="center"></el-table-column>
        <el-table-column prop="seat_count" label="坐席额度" align="center"></el-table-column>
        <el-table-column prop="seat_phone_count" label="坐席数" align="center"></el-table-column>
        <el-table-column prop="money" label="账户余额" align="center"></el-table-column>
        <el-table-column prop="y_money" label="预续签金额" align="center"></el-table-column>
        <el-table-column prop="s_money" label="应续费金额" align="center" class="hide"></el-table-column>
        <el-table-column prop="r_money" label="实续费金额" align="center"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center">
          <template v-slot="scope">
            <div>
              {{ setDate(scope.row.create_time) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="change_time" label="更新时间" align="center">
          <template v-slot="scope">
            <div>
              {{ setDate(scope.row.change_time) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="mini">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>
    <!-- 编辑弹出框 -->
    <el-dialog title="处理续费" :visible.sync="updateShow" width="30%">
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item label="实续费金额" v-if="(parentId != 3555 || role != '客服') && getRenewBool()">
          <el-input-number size="mini" v-model="forms.r_money" :precision="3" :step="1" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="续签结果">
          <el-select size="mini" v-model="forms.renewResult" style="width: 50%" placeholder="续签结果"
            v-if="roleName == '管理员'">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select size="mini" v-model="forms.renewResult" style="width: 50%" placeholder="续签结果"
            v-if="roleName != '管理员'">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="forms.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateShow = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  companyRenewAddWithRenew,
  companyRenewFindNoRenew,
} from "@/api/company_renew";
import { getYMDHMS } from "@/utils/timestamp";
export default {
  data() {
    return {
      role: null,
      forms: {},
      updateShow: false,
      options: [
        {
          value: 0,
          label: "关停",
        },
        {
          value: 1,
          label: "已续费",
        },
        {
          value: 2,
          label: "待续费",
        },
        {
          value: 3,
          label: "商定中",
        },
        {
          value: 4,
          label: "不续费",
        },
        {
          value: 5,
          label: "转销售",
        },
        {
          value: 6,
          label: "新增坐席",
        },
        {
          value: 7,
          label: "减坐席",
        },
        {
          value: 8,
          label: "补开坐席",
        },
        {
          value: 9,
          label: "续费新增坐席",
        },
        {
          value: 10,
          label: "续费减坐席",
        },
      ],
      options1: [
        {
          value: 0,
          label: "关停",
        },
        {
          value: 2,
          label: "待续费",
        },
        {
          value: 3,
          label: "商定中",
        },
        {
          value: 4,
          label: "不续费",
        },
        {
          value: 5,
          label: "转销售",
        },
        {
          value: 7,
          label: "减坐席",
        },
        {
          value: 8,
          label: "补开坐席",
        },
      ],
      id: null,
      timeValue: null,
      callStarttime: null,
      callEndtime: null,
      realname: null,
      username: null,
      operators: null,
      seat_rate: null,
      seat_count: null,
      seat_phone_count: null,
      money: null,
      y_money: null,
      s_money: null,
      r_money: null,
      create_time: null,
      change_time: null,
      remark: null,
      parentId: null,
      realname: null,
      operators: null,
      renewResult: null,
      roleName: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.role = localStorage.getItem("role");
    this.getList();
  },
  methods: {
    getRenewBool() {
      let arr = [1, 6, 9, 10]
      let bool = arr.includes(this.forms.renewResult)
      if (!bool) {
        this.forms.r_money = 0
      }
      return bool
    },
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    getDate(date) {
      this.callStarttime = this.formatDate(date[0]);
      this.callEndtime = this.formatDate(date[1]);
    },
    // 格式化日期
    setDate(date) {
      let time = getYMDHMS("YYYY-mm-dd HH:MM:SS", date);
      console.log("转换后的时间", time);
      return time;
    },
    // 编辑弹出框
    update(row) {
      this.updateShow = true;
      this.forms = row.row;
    },
    // 编辑确定
    updateSubmit() {
      let params = {
        parentId: this.forms.id,
        r_money: this.forms.r_money,
        renewResult: this.forms.renewResult,
        remark: this.forms.remark,
      };
      let subBool = this.getRenewBool()
      if (subBool){
        if(this.forms.r_money == 0){
          Message.warning("金额不能为0!");
          return false
        }else{
          params.r_money = this.forms.r_money
        }
      }
      if (this.forms.remark == null) {
        Message.warning("请填写备注！");
      } else {
        let res = companyRenewAddWithRenew(params);
        res.then((res) => {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        });
      }
    },
    // 查询
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.id = null;
      this.realname = null;
      this.operators = null;
      this.renewResult = null;
      this.callStarttime = null;
      this.callEndtime = null;
      this.timeValue = null;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        operators: this.operators,
        parentId: this.id,
        realname: this.realname,
        renewResult: this.renewResult,
        page: this.page,
        size: this.size,
      };
      if (this.callStarttime) {
        params.sta = this.callStarttime
        params.end = this.callEndtime
        params.no_or_with_renew = 1

      } else {
        params.no_or_with_renew = -1
      }

      let res = companyRenewFindNoRenew(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#processed {
  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          .el-select {
            width: 100%;
          }

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>