/**
 * 日期相关
 */

// 转换日期
export const getYMDHMS = (format, date) => {
    // console.log("转换日期", format, date);
    if (!date) {
        return "";
    }
    date = new Date(date);
    const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
    };
    Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
            format = format.replace(
                ret[1],
                ret[1].length === 1
                    ? dataItem[item]
                    : dataItem[item].padStart(ret[1].length, "0")
            );
        }
    });
    return format;
}